import React, { useEffect, useRef } from 'react'
import cs from 'classnames'
import addYmapScript from '../../util/addYmapScript'
import style from './ContactMap.module.scss'
import mapPointIcon from '../../images/map-point-icon.svg'

const initYmap = (element, point, title) => {
  const ymap = new window.ymaps.Map(element, {
    center: [59.911290518341055, 30.50183179869501],
    zoom: 15,
    controls: ['zoomControl'],
  })
  ymap.behaviors.disable('scrollZoom')
  const geoObject = new window.ymaps.GeoObject(
    {
      geometry: point,
      properties: {
        balloonContent: title,
      },
    },
    {
      // Опции.
      // Необходимо указать данный тип макета.
      iconLayout: 'default#image',
      // Своё изображение и`конки метки.w
      iconImageHref: mapPointIcon,
      // Размеры метки.
      iconImageSize: [36, 48],
      // Смещение левого верхнего угла иконки относительно
      // её "ножки" (точки привязки).
      iconImageOffset: [-18, -48],
      hasBalloon: true,
      openBalloonOnClick: true,
    }
  )

  ymap.geoObjects.add(geoObject)
  ymap.setCenter([point.coordinates[0], point.coordinates[1]])
}

const ContactMap = ({ point = null, title = '', inView = false, className = '' }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (inView) {
      addYmapScript(() => {
        initYmap(ref.current, point, title)
      })
    }
  }, [inView])

  return (
    <div className={cs(style.container, className)}>
      <div className={style.map} ref={ref} />
    </div>
  )
}

export default ContactMap
