import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HorizontalScrolling from '../../components/HorizontalScrolling'
import ContactForm from '../../components/ContactForm/ContactForm'
import PlaceSection from '../../components/PlaceSection'
import contactFormImage from '../../images/contact-form.jpg'

const ContactsPage = ({
  data: {
    wordpressAcfOptions: {
      headless_settings: { addresses },
    },
  },
}) => (
  <HorizontalScrolling>
    <section className="screen-width-section">
      <ContactForm overtitle="Cвязаться с нами" title="Напишите прямо сейчас" />
    </section>
    {addresses && addresses.length
      ? addresses.map(office => (
        <PlaceSection
          className="max-w-5xl screen-width-section"
          key={office.title}
          overtitle={office.overtitle}
          title={office.title}
          phone={office.phone}
          email={office.email}
          address={office.address}
          openingHours={office.opening_hours}
          mapPoint={
              office.address_map ? JSON.parse(office.address_map) : null
            }
        />
        ))
      : null}
    <div
      className="max-w-lg hidden lg:block bg-cover w-screen"
      style={{ backgroundImage: `url(${contactFormImage})` }}
    />
  </HorizontalScrolling>
)

ContactsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactsPage

export const query = graphql`
  query {
    wordpressAcfOptions {
      headless_settings {
        addresses {
          overtitle
          title
          opening_hours
          phone
          email
          address
          address_map
        }
      }
    }
  }
`
