export default (onLoadFunc = f => f) => {
  if (!window.ymapOnLoadFunctionsStack) {
    window.ymapOnLoadFunctionsStack = []
  }

  if (!window.runYmapOnLoadFunctionsStack) {
    window.runYmapOnLoadFunctionsStack = () => {
      window.ymapOnLoadFunctionsStack.forEach(f => f())
      window.ymapOnLoadFunctionsStack = []
      window.ymapIsReady = true
    }
  }

  if (!window.ymapScriptAdded) {
    const elem = document.createElement('script')
    elem.type = 'text/javascript'
    elem.src = `//api-maps.yandex.ru/2.1/?lang=ru-RU&onload=runYmapOnLoadFunctionsStack`
    document.getElementsByTagName('body')[0].appendChild(elem)
    window.ymapScriptAdded = true
  }

  if (window.ymapIsReady) {
    onLoadFunc()
  } else {
    window.ymapOnLoadFunctionsStack.push(onLoadFunc)
  }
}
