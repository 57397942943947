import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import cs from 'classnames'
import style from './PlaceSection.module.scss'
import { stripPhone } from '../../util/helpers'
import ContactMap from '../ContactMap'

const PlaceSection = ({
  overtitle = '',
  title = '',
  phone = '',
  email = '',
  address = '',
  openingHours = '',
  mapPoint = null,
  className = '',
}) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  let timeout = 0

  return (
    <section
      className={cs(style.root, className, {
        'in-view': inView,
      })}
    >
      <div
        ref={inViewRef}
        className="mx-auto max-w-xl lg:max-w-2xl flex flex-col h-full primary-text-section"
      >
        <div
          className={cs(
            style.overtitle,
            'primary-appearance',
            'primary-text-section__overtitle'
          )}
        >
          {overtitle}
        </div>
        <h2
          className={cs(
            style.title,
            'primary-appearance',
            'primary-text-section__dark-title'
          )}
          style={{ transitionDelay: `${(timeout += 150)}ms` }}
        >
          {title}
        </h2>
        <div className="mt-8">
          <ul className="accent-list">
            {phone ? (
              <li
                className="primary-appearance"
                style={{ transitionDelay: `${(timeout += 150)}ms` }}
              >
                <a href={`tel:${stripPhone(phone)}`}>{phone}</a>
              </li>
            ) : null}
            {email ? (
              <li
                className="primary-appearance"
                style={{ transitionDelay: `${(timeout += 150)}ms` }}
              >
                <a href={`mailto:${email}`}>{email}</a>
              </li>
            ) : null}
          </ul>
        </div>
        {address ? (
          <div
            className={cs(
              'mt-12 text-xs font-400 primary-uppercase',
              style.address
            )}
          >
            {address}
          </div>
        ) : null}
        {openingHours ? (
          <div
            className={cs(
              'mt-2 text-xs font-400 primary-uppercase',
              style.openingHours
            )}
            dangerouslySetInnerHTML={{ __html: openingHours }}
          />
        ) : null}
        <ContactMap
          point={mapPoint}
          inView={inView}
          className="mt-8 flex-auto"
        />
      </div>
    </section>
  )
}

export default PlaceSection
